import { useEffect, useState } from 'react'
import axios from 'axios'
import './App.css'
import { SpacerH, ToggleSwitch } from '@cb/apricot-react'
import ProductList from './components/ProductList'
import ProductTable from './components/ProductTable'
import SearchBar from './components/SearchBar'
import SortDropdown from './components/SortDropdown'
import QuickFilters from './components/QuickFilters'
import Product from './components/Product'
import { Set } from 'collections/set'

const App = () => {
  const [products, setProducts] = useState([])
  const [productsToDisplay, setProductsToDisplay] = useState([])
  const [tableView, setTableView] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [position, setPosition] = useState(0)
  const [quickFilters, setQuickFilters] = useState([])
  const [sortBy, setSortBy] = useState({
    label: 'Product Name (Ascending)',
    field: 'name',
    ascending: true,
  })
  const [rows, setRows] = useState([])
  const [compactView, setCompactView] = useState(false)

  /* Toggle table view with T */
  /*
  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 't') {
        setTableView(!tableView)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [tableView])
  */

  /* Maintain page position between selected products */
  useEffect(() => {
    if (!selectedProduct) {
      // Restore scroll position as user returns to list view
      window.scrollTo(0, position)
    } else {
      window.scrollTo(0, 0)
    }
  }, [selectedProduct])

  /* Fetch products */
  useEffect(() => {
    const url = 'https://3def72mbz4.execute-api.us-east-1.amazonaws.com/prod/'
    axios
      .get(url)
      .then(res => {
        // Populate products
        const products = res.data.products
        products.forEach(product => {
          product.label = product.name
          product.teamCount = product.teams?.split(/[;,&]/).length
        })
        setProducts(products)
      })
      .catch(err => console.log(err))
  }, [])

  /* Create quick filters */
  useEffect(() => {
    // Create one quick filter for each unique portfolio
    const portfolios = new Set(products.map(p => p.portfolio).sort()).toArray()
    const lifecycles = ['Introduction', 'Growth', 'Mature', 'Decline']
    if (products.length) {
      setQuickFilters(
        portfolios
          .map(portfolio => ({
            filterType: 'portfolio',
            filterName: portfolio,
            active: false,
            filterFunction: product => product.portfolio === portfolio,
          }))
          .sort((a, b) => a.filterName.localeCompare(b.filterName))
          .concat(
            lifecycles.map(lifecycle => ({
              filterType: 'lifecycle',
              filterName: lifecycle,
              active: false,
              filterFunction: product =>
                product.vitals?.lifecycle !== undefined &&
                product.vitals?.lifecycle.search(lifecycle) !== -1,
            }))
          )
      )
    }
  }, [products])

  /* Sort and filter products */
  useEffect(() => {
    // Apply quick filters
    let toDisplay = products
    const filtersActive = quickFilters.some(f => f.active)
    if (filtersActive) {
      toDisplay = []
      quickFilters.forEach(f => {
        if (f.active) {
          toDisplay = new Set(toDisplay)
            .union(new Set(products.filter(f.filterFunction)))
            .toArray()
        }
      })
    }

    toDisplay.sort((a, b) => {
      if (!sortBy.ascending) [a, b] = [b, a]
      return a[sortBy.field].localeCompare(b[sortBy.field])
    })

    setProductsToDisplay(toDisplay.toArray())
  }, [products, quickFilters, sortBy, tableView])

  // Sort
  const sorts = [
    {
      label: 'Product Name (Ascending)',
      field: 'name',
      ascending: true,
    },
    {
      label: 'Product Name (Descending)',
      field: 'name',
      ascending: false,
    },
  ]

  return (
    <div className="App">
      <main className="cb-padding-top-32 cb-padding-bottom-32">
        {selectedProduct !== null ? (
          <Product
            product={products.find(p => p.id === selectedProduct)}
            selectProduct={setSelectedProduct}
          />
        ) : (
          <></>
        )}
        {/* PRODUCT LIST HEADER */}
        {selectedProduct === null ? (
          <>
            <div className="container cb-padding-top-8">
              <div className="row">
                <div className="col-xs-8 offset-xs-2 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                  <div className="cb-h3 cb-font-weight-medium cb-padding-bottom-8">
                    College Board Products
                  </div>
                  <div className="cb-input cb-condensed cb-floating-label"></div>
                  <hr className="solid" />
                  <br />
                  <SearchBar
                    products={products}
                    selectProduct={setSelectedProduct}
                    setPosition={setPosition}
                  />
                  <br />
                  <div className="row no-gutters">
                    <QuickFilters
                      setProducts={setProducts}
                      quickFilters={quickFilters}
                      setQuickFilters={setQuickFilters}
                    />
                  </div>
                  <br />
                  <ToggleSwitch
                    onLabel="Compact View"
                    offLabel="Compact View"
                    on={compactView}
                    onChange={() => setCompactView(!compactView)}
                  />
                  <SpacerH />
                  <ToggleSwitch
                    onLabel="Table View"
                    offLabel="Table View"
                    on={tableView}
                    onChange={() => setTableView(!tableView)}
                  />
                  <div className="row no-gutters justify-content-between cb-font-weight-light cb-padding-8">
                    <span className="cb-font-size-small cb-padding-top-24">
                      {tableView
                        ? `${
                            rows.filter(r => !r.isGroup && !r.isTotal).length
                          } Row(s) Found ----- Press T to exit table view`
                        : `${productsToDisplay.length} Product(s) Found`}
                    </span>
                    {!tableView ? (
                      <SortDropdown
                        sorts={sorts}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {tableView ? (
              <ProductTable
                products={productsToDisplay}
                sorts={sorts}
                setSortBy={setSortBy}
                rows={rows}
                setRows={setRows}
              />
            ) : (
              <ProductList
                products={productsToDisplay}
                setProducts={setProductsToDisplay}
                selectProduct={setSelectedProduct}
                setPosition={setPosition}
                quickFilters={quickFilters}
                setQuickFilters={setQuickFilters}
                sortBy={sortBy}
                setSortBy={setSortBy}
                compactView={compactView}
                setCompactView={setCompactView}
              />
            )}
          </>
        ) : (
          <></>
        )}
      </main>
    </div>
  )
}

export default App
