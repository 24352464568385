import { Button, PrimaryButton, SpacerH, SpacerV } from '@cb/apricot-react'

const QuickFilter = ({ filter, quickFilters, setQuickFilters }) => {
  const onClick = () => {
    let idx = quickFilters.indexOf(filter)
    let newFilter = { ...filter, active: !filter.active }
    setQuickFilters(
      quickFilters
        .slice(0, idx)
        .concat(newFilter)
        .concat(quickFilters.slice(idx + 1))
    )
    /*
    setQuickFilters(
      quickFilters
        .filter(f => f !== filter)
        .concat({ ...filter, active: !filter.active })
        .toSorted((a, b) => a.filterName.localeCompare(b.filterName))
    )
    */
  }

  return (
    <div className="col cb-padding-4">
      {filter.active ? (
        <PrimaryButton small onClick={onClick}>
          {filter.filterName}
        </PrimaryButton>
      ) : (
        <Button small onClick={onClick}>
          {filter.filterName}
        </Button>
      )}
      <SpacerH size="8" />
    </div>
  )
}

const QuickFilters = ({ quickFilters, setQuickFilters }) => {
  return (
    <>
      {quickFilters
        .sort((a, b) => a.name > b.name)
        .map(f => (
          <QuickFilter
            key={f.filterName}
            filter={f}
            quickFilters={quickFilters}
            setQuickFilters={setQuickFilters}
          />
        ))}
    </>
  )
}

export default QuickFilters
