// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.hover-effect:hover {
  transform: scale(0.9);
}

.hover-effect {
  transform: scale(0.8);
  transition: 0.35s;
}

.product-card {
  transition: 0.35s;
}

.compact-card {
  flex: 1 1;
  flex-basis: 45%;
}

.search-bar {
  flex: 1 1;
  flex-basis: 100%;
}

.product-details-card {
  transition: 0.35s;
}

.product-details-card:hover {
  transform: scale(1.005);
  animation: card-hover 2;
}

.product-details {
  transition: 0.35s;
}

.product-details:hover {
  transform: scale(1.0125);
  animation: card-hover 2;
}

.product-card:hover {
  transform: scale(1.0125);
  animation: card-hover 2;
}

.product-card:hover .product-card-title {
  text-decoration: underline;
}

.cb-card-title {
  padding-bottom: 0.3em;
  border-bottom: 1px dashed grey;
}

.product-card-list {
  display: flex;
  align-items: center;
  padding: 2em;
}

.product-container {
  max-width: 440px;
}

.product-section {
  overflow-x: hidden; 
  min-width: 100px;
  max-width: 300px;
}

.main {
  padding: 20%;
}

hr.solid {
 border-width: .5px;
}

hr.dashed {
  border-top: 1px dashed;
  border-bottom: 0px;
}

.quickfilter {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,SAAO;EACP,eAAe;AACjB;;AAEA;EACE,SAAO;EACP,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,wBAAwB;EACxB,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;EACxB,uBAAuB;AACzB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;EACrB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;CACC,kBAAkB;AACnB;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ","sourcesContent":["\n.hover-effect:hover {\n  transform: scale(0.9);\n}\n\n.hover-effect {\n  transform: scale(0.8);\n  transition: 0.35s;\n}\n\n.product-card {\n  transition: 0.35s;\n}\n\n.compact-card {\n  flex: 1;\n  flex-basis: 45%;\n}\n\n.search-bar {\n  flex: 1;\n  flex-basis: 100%;\n}\n\n.product-details-card {\n  transition: 0.35s;\n}\n\n.product-details-card:hover {\n  transform: scale(1.005);\n  animation: card-hover 2;\n}\n\n.product-details {\n  transition: 0.35s;\n}\n\n.product-details:hover {\n  transform: scale(1.0125);\n  animation: card-hover 2;\n}\n\n.product-card:hover {\n  transform: scale(1.0125);\n  animation: card-hover 2;\n}\n\n.product-card:hover .product-card-title {\n  text-decoration: underline;\n}\n\n.cb-card-title {\n  padding-bottom: 0.3em;\n  border-bottom: 1px dashed grey;\n}\n\n.product-card-list {\n  display: flex;\n  align-items: center;\n  padding: 2em;\n}\n\n.product-container {\n  max-width: 440px;\n}\n\n.product-section {\n  overflow-x: hidden; \n  min-width: 100px;\n  max-width: 300px;\n}\n\n.main {\n  padding: 20%;\n}\n\nhr.solid {\n border-width: .5px;\n}\n\nhr.dashed {\n  border-top: 1px dashed;\n  border-bottom: 0px;\n}\n\n.quickfilter {\n  color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
