import { InputDropdownR } from '@cb/apricot-react'
import { useState } from 'react'

const SearchBar = ({ products, selectProduct, setPosition }) => {
  const [dataItems, setDataItems] = useState([])

  return (
    <InputDropdownR
      placeholder="Search for products..."
      dataItems={dataItems}
      scrollLimit={5}
      dropdownOverlay
      condensed
      closeOnSelect
      highlightInputValue
      clearable={false}
      callBack={(label, _) => {
        setPosition(0, window.scrollY)
        selectProduct(products.find(p => p.name === label).id)
      }}
      onChange={(_, value) => {
        if (value) {
          setDataItems(
            products.filter(
              product =>
                !!product.name.toLowerCase().includes(value.toLowerCase())
            )
          )
        } else {
          setDataItems([])
        }
      }}
    />
  )
}

export default SearchBar
