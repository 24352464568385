import { Table, Column } from '@cb/apricot-react'
import { useEffect } from 'react'

const ProductTable = ({ products, rows, setRows }) => {
  // Populate table with rows of products
  useEffect(() => {
    // Get an array of unique groups, insert them along side the products
    const groups = new Set(
      products.filter(p => p.group).map(p => p.group)
    ).toArray()

    // Add a row for this group, then add a row for each product in it
    let newRows = []
    groups.forEach(g => {
      const productsInGroup = products.filter(p => p.group === g)
      const groupAssetCount = productsInGroup.reduce(
        (p, c) => p + Number(c.vitals?.assetCount),
        0
      )
      const groupTeamCount = productsInGroup.reduce(
        (p, c) => p.union(new Set(c.teams.split(', '))),
        new Set()
      ).length
      const groupRow = {
        name: g,
        isGroup: true,
        assetCount: groupAssetCount,
        teamCount: groupTeamCount,
        order: Math.min(...productsInGroup.map(p => p.order)) - 1,
      }
      const productRows = productsInGroup.map(p => {
        return {
          ...p,
          lifecycle: p.vitals?.lifecycle,
          usagePattern: p.vitals?.usagePattern,
          students: p.customers?.students,
          educators: p.customers?.educators,
          assetCount: p.vitals?.assetCount,
        }
      })
      newRows = [...newRows, groupRow, ...productRows]
    })
    const totalAssetCount = newRows
      .filter(r => r.isGroup)
      .reduce((p, c) => p + c.assetCount, 0)
    const totalTeamCount = newRows
      .filter(r => r.isGroup)
      .reduce((p, c) => p + c.teamCount, 0)
    const totalRow = {
      name: 'Totals',
      assetCount: totalAssetCount,
      teamCount: totalTeamCount,
      isTotal: true,
      order: -1,
    }
    newRows = [totalRow, ...newRows].sort((a, b) => a.order - b.order)
    setRows(newRows)
  }, [products])

  return rows.length ? (
    <div className="container cb-spacerv-top-16">
      <div className="row">
        {/* <div className="col-xs-8 offset-xs-2 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 cb-gutterv-24"> */}
        <Table
          data={rows}
          rowSelected={r => r.isGroup}
          rowHighlighted={r => r.isTotal}
        >
          <Column field="name" title="Software Products" />
          <Column field="description" title="Description" />
          <Column field="lifecycle" title="Product Lifecycle" />
          <Column field="usagePattern" />
          <Column
            field="students"
            title="# of users (Students) over last 12 months"
          />
          <Column
            field="educators"
            title="# of users (Educators) over last 12 months"
          />
          <Column field="assetCount" title="# of Assets" />
          <Column field="teamCount" title="# of Tech Teams" />
          <Column field="teams" title="Team Names" />
        </Table>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default ProductTable
