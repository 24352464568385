import ProductCard from './ProductCard'
import { useState } from 'react'
import CompactCard from './CompactCard'

const ProductList = ({
  selectProduct,
  products,
  setProducts,
  quickFilters,
  setQuickFilters,
  setPosition,
  sortBy,
  setSortBy,
  compactView,
  setCompactView,
}) => {
  return (
    <>
      {/* PRODUCT LIST */}
      <div className="container cb-spacerv-top-16">
        <div className="row">
          <div className="col-xs-8 offset-xs-2 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 cb-gutterv-24">
            {compactView
              ? products.map(p => (
                  <CompactCard
                    key={p.id}
                    product={p}
                    selectProduct={selectProduct}
                    setPosition={setPosition}
                  />
                ))
              : products.map(p => (
                  <ProductCard
                    key={p.id}
                    product={p}
                    selectProduct={selectProduct}
                    setPosition={setPosition}
                  />
                ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductList
