import { Dropdown, DropdownItem } from '@cb/apricot-react'

const DropdownItems = ({ sorts, setSortBy }) =>
  sorts.map(s => (
    <DropdownItem key={s.label} label={s.label} onClick={() => setSortBy(s)} />
  ))

const SortDropdown = ({ sorts, sortBy, setSortBy }) => {
  return (
    <Dropdown
      title={sortBy.label}
      closeOnClick
      classNameToggle="cb-no-padding cb-font-size-regular"
    >
      <DropdownItems sorts={sorts} setSortBy={setSortBy} />
    </Dropdown>
  )
}

export default SortDropdown
