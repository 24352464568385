import { Icon } from '@cb/apricot-react'

const CompactCard = ({ product, selectProduct, setPosition }) => {
  const formatName = name => {
    // const lengthLimit = 30
    // if (name.length > lengthLimit)
    //   return name.substring(0, lengthLimit).concat('...')
    return name
  }
  return (
    <div
      className="product-card compact-card cb-card cb-border cb-blue5-border cb-border1 cb-font-weight-light cb-font-size-small"
      onClick={() => {
        setPosition(window.scrollY)
        selectProduct(product.id)
      }}
    >
      <div className="cb-card-content cb-font-weight-regular">
        <div>
          <Icon className="cb-padding-right-8 cb-blue5-color" name="acorn" />
          <span className="cb-padding-right-8">{formatName(product.name)}</span>
          <Icon className="cb-font-weight-black" name="east" size="14" />
        </div>
      </div>
    </div>
  )
}

export default CompactCard
