import { Icon } from '@cb/apricot-react'

const ProductCard = ({ product, selectProduct, setPosition }) => {
  return (
    <div className="product-card cb-card cb-border cb-blue5-border cb-border1 cb-font-weight-light">
      <div className="cb-card-content">
        <div
          className="cb-card-title"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setPosition(window.scrollY)
            selectProduct(product.id)
          }}
        >
          <div>
            <span className="cb-padding-right-8">{product.name}</span>
            <Icon className="cb-font-weight-black" name="east" size="14" />
          </div>
        </div>
        <p>
          <Icon className="cb-padding-right-16 cb-blue5-color" name="acorn" />
          {`${product.portfolio}`}
        </p>
      </div>
      <div>
        <p>
          <Icon className="cb-padding-right-16 cb-blue5-color" name="graph" />
          {product.vitals ? product.vitals.lifecycle : '?'}
        </p>
      </div>
      <div>
        <p>
          <Icon
            className="cb-padding-right-16 cb-blue5-color"
            name="cal-full"
          />
          {product.vitals ? product.vitals.usagePattern : '?'}
        </p>
      </div>
      <div>
        <p>
          <Icon
            className="cb-padding-right-16 cb-blue5-color"
            name="accessibility"
          />
          {product.teams}
        </p>
      </div>
    </div>
  )
}

export default ProductCard
